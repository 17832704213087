<style lang="less" scoped>
  .item-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    box-sizing: border-box;
    .wrap {
      width: 100%;
      .item {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #EEE;
        background-color: #FFF;
        padding: 10px;
        position: relative;
        transition: all .3s;
        .remark {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: keep-all;
          width: 100%;
        }
        .title {
          transition: all .3s;
          font-weight: bold;
        }
        .successRate {
          font-size: 15px;
          font-weight: bold;
        }
        &:hover {
          border: 1px dashed #2F5BEA;
        }
        &.active {
          border-style: solid;
          border-color: #2F5BEA;
          .title {
            color: #2F5BEA;
          }
        }
      }
      & + .wrap {
        margin-top: 10px;
      }
    }
  }
  .remark {
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
  }
</style>

<template>
  <AppPage simple :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row>
        <i-col :flex="1">
          <Button long @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
        </i-col>
        <i-col v-if="mode !== 'exec' && $authFunsProxy.manage" style="margin-left: 10px;" :flex="1">
          <Button long @click="itemClick(null)" type="primary" icon="md-add">新增</Button>
        </i-col>
      </Row>
      <i-input style="margin-top: 10px;" placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
        <Select slot="prepend" style="width: 100px" v-model="query.key">
          <Option value="name">名称</Option>
        </Select>
      </i-input>
      <Dropdown v-if="mode !== 'exec'" style="margin-top: 10px;display: block;" @on-click="exeStatus => onSearch({ exeStatus })">
        <template v-for="item in scmGoodsPurchaseStatusList">
          <Tag size="large" style="cursor: pointer; display: block; text-align: center;" :key="item.value" :color="item.color" v-if="query.exeStatus === String(item.value)">状态: {{item.label}}<Icon type="ios-arrow-down"></Icon></Tag>
        </template>
        <Tag size="large" style="cursor: pointer; display: block; text-align: center;" color="default" v-if="query.exeStatus === 'all'">状态: 全部<Icon type="ios-arrow-down"></Icon></Tag>
        <DropdownMenu slot="list">
          <DropdownItem :selected="query.exeStatus === 'all'" name="all">全部</DropdownItem>
          <DropdownItem v-for="item in scmGoodsPurchaseStatusList" :key="item.value" :selected="query.exeStatus === String(item.value)" :name="String(item.value)">{{ item.label }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </template>
    <div class="item-list">
      <div class="wrap" v-for="item in dataList" :key="item.id">
        <div class="item" @click="showDetail(item)" :class="{ active: chooseData && chooseData.id === item.id }">
          <Row align="middle" justify="space-between">
            <span class="title">{{ item.name }}</span>
            <PlanStatus @click.native="showDetail(item)" :status="item.exeStatus" />
          </Row>
          <Row justify="space-between" style="margin-top: 10px;">
            <i-col>计划完成: </i-col>
            <i-col>
              {{ (item.planEndTime || '-') }}
            </i-col>
          </Row>
          <Row justify="space-between" style="margin-top: 10px;">
            <i-col>实际完成: </i-col>
            <i-col>
              {{ (item.endTime || '-') }}
            </i-col>
          </Row>
          <Row style="margin-top: 10px">
            <i-col>
              <Tooltip class="block" :content="item.remark" :max-width="300" :delay="500" transfer>
                <div class="remark">
                  {{ item.remark || '-' }}
                </div>
              </Tooltip>
            </i-col>
          </Row>
          <PlanAction v-if="mode !== 'exec' && $authFunsProxy.manage" :status="item.exeStatus" @item-click="action => planAction(action, item)" style="margin-top: 10px;" />
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <Drawer placement="right" :title="formData && formData.id ? '修改' : '新增'" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <SaleafterForm
            v-if="status.form"
            :data="formData"
            ref="form"
          />
        </DrawerAction>
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import request from '../../../api'
import AppPage from '../../page/base.vue'
import DrawerAction from '../../drawer/action.vue'
import PlanStatus from '../../plan/status.vue'
import PlanAction from '../../plan/action.vue'
import SaleafterForm from './form/index.vue'

export default {
  components: { AppPage, PlanStatus, PlanAction, SaleafterForm, DrawerAction },
  props: {
    mode: { type: String, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 20,
        total: 0
      },
      query: {
        key: 'name',
        value: null,
        exeStatus: 'all'
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false
      },
      formData: null,
      chooseData: null,
    }
  },
  computed: {
    scmGoodsPurchaseStatusList () {
      return this.$store.getters.scmGoodsPurchaseStatusList
    }
  },
  methods: {
    planAction (action, item) {
      if (action.type === 'btn') {
        if (action.value === 'update') {
          this.itemClick(item)
        } else if (action.value === 'del') {
          this.itemDel(item)
        }
      } else if (action.type === 'status') {
        this.updateStatus(item, action.value)
      }
    },
    async updateStatus (item, exeStatus) {
      try {
        await request.put('/goods_sale_after/exe_status/' + item.id, { exeStatus })
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({
          title: '状态切换失败',
          content: e.message
        })
      }
    },
    showDetail (item) {
      this.chooseData = item
      this.$emit('item-choose', item)
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        delete data.exeStatus
        delete data.endTime
        if (data.id) {
          await request.put('/goods_sale_after/' + data.id, data)
          this.loadData()
        } else {
          await request.post('/goods_sale_after', data)
          this.refresh()
        }
        this.$Message.success('操作成功')
        this.status.form = false
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    async itemDel (item) {
      await request.delete('/goods_sale_after/' + item.id)
      this.$Message.success('已删除')
      this.loadData()
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'name'
      this.query.value = null
      this.query.exeStatus = 'all'
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {

      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.query.exeStatus !== 'all') {
        params.exeStatus = Number(this.query.exeStatus)
      }

      if (this.mode === 'exec') {
        params.exeStatus = 1
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_sale_after', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.statusData = this.scmGoodsPurchaseStatusList.find(v => v.value === item.status)
        })

        this.pager.total = res.total
        this.dataList = res.data
        if (!this.chooseData || (this.chooseData && res.data.findIndex(v => v.id === this.chooseData.id) === -1)) {
          this.chooseData = res.data.length ? res.data[0] : null
          this.$emit('item-choose', this.chooseData)
        } else {
          this.$emit('reload')
        }
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
