<style scoped>
</style>

<template>
  <!-- <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange"> -->
  <AppPage>
    <template v-slot:header>
      <Row type="flex" justify="space-between" :wrap="false" align="middle" style="margin-bottom: 10px;">
        <i-col style="flex: 1; width: 0;overflow-x: auto;white-space: nowrap;">
          <Row align="middle">
            <i-col>
              <fm-title v-if="saleData" :title-text="saleData.name"></fm-title>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
          <Button v-if="status.mode" type="primary" @click="status.mode = false;loadData()" style="margin-left: 10px;">查看明细统计</Button>
          <Button type="primary" v-else @click="status.mode = true;loadData()" style="margin-left: 10px;">返回</Button>
          <Button v-if="$route.query.back" @click="$router.back()" style="margin-left: 10px;" icon="ios-arrow-back">返回</Button>
          <!-- <Button v-if="useUpdate" @click="itemClick(null)" style="margin-left: 10px;" :loading="loading.load" icon="md-add" type="primary">新增</Button> -->
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <div :style="{ height: contentHeight + 'px' }" style="display: flex;position: relative;overflow: hidden;">
        <div style="flex: 1; width: 0">
          <Table row-key="id" :load-data="handleLoadData" :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
            <!-- <template v-slot:goodsStatus="{ row }">
              <Tag class="noborder" :color="row.goodsStatusData.color">{{ row.goodsStatusData.label }}</Tag>
            </template> -->
            <template v-slot:planVolume="{ row }">
              <InputNumber
                v-if="useUpdate"
                :min="1"
                :active-change="false"
                @on-change="planVolume => submit(row.id, { planVolume: planVolume || 0 })"
                :value="row.planVolume"
                :formatter="value => `${value}${row.goodsUnit || ''}`"
                :parser="value => value.replace(row.goodsUnit, '')"
              />
              <span v-else>{{ row.planVolume }}{{ row.goodsUnit }}</span>
            </template>
            <template v-slot:outVolume="{ row }">
              {{ row.outVolume !== null ? (row.outVolume + ' ' + row.goodsUnit) : '-' }}
            </template>
            <template v-slot:action="{ row }">
              <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
                <Button :loading="loading.del" type="warning" size="small">删除</Button>
              </Poptip>
            </template>
          </Table>
        </div>
        <GroupSelect :style="{pointerEvents: loading.load ? 'none' : 'auto'}" v-if="useUpdate" @select="onSelectGoods" style="width: 225px;height: 100%;margin-left: 10px;border-left: 1px solid #EEE;" />
      </div>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import request from '../../api/index'
import GroupSelect from '../../components/goods/base/group/select/goods.vue'

export default {
  components: { AppPage, GroupSelect },
  props: {
    goodsSaleAfterId: { type: Number, default: null }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {},
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        mode: true
      },
      formData: null,
      saleData: null
    }
  },
  computed: {
    useUpdate () {
      const status = this.saleData ? this.saleData.exeStatus : null
      return [-1,0].includes(status) && this.status.mode && this.$authFunsProxy.manage
    },
    scmGoodsStatusList () {
      return this.$store.getters.scmGoodsStatusList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    currentGoodsSaleAfterId () {
      let goodsSaleAfterId = this.goodsSaleAfterId || this.$route.query.goodsSaleAfterId
      return goodsSaleAfterId && !isNaN(goodsSaleAfterId) ? Number(goodsSaleAfterId) : null
    },
    columns () {
      const columns = [
        {
          title: '物品', key: 'goodsId', fixed: 'left', width: 180, tree: true, render (h, { row }) {
            if (row.goodsGroupName) {
              return h('span', [
                h('span', row.goodsGroupName),
                h('span', {
                  style: { color: '#005AFF' }
                }, '（物品组）')
              ])
            } else {
              return h('span', row.goodsName)
            }
          }
        },
        // { title: '状态', key: 'goodsStatus', slot: 'goodsStatus', minWidth: 100 },
        { title: '规格型号', key: 'goodsGgxh', minWidth: 100 },
        { title: '计划量', key: 'planVolume', slot: 'planVolume', minWidth: 100 },
        { title: '出库量', key: 'outVolume', slot: 'outVolume', minWidth: 100 },
        // { title: '备注', key: 'remark', ellipsis: true, tooltip: true, width: 160 }
      ]
      if (this.useUpdate) {
        columns.push({ title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 70 })
      }
      return columns
    }
  },
  watch: {
    currentGoodsSaleAfterId () {
      if (this.routerName === this.$route.name) {
        this.loadSaleData()
        this.refresh()
      }
    }
  },
  methods: {
    async loadSaleData () {
      const res = await request.get('/goods_sale_after', { id: this.currentGoodsSaleAfterId })
      this.saleData = res.length ? res[0] : null
    },
    async onSelectGoods ({ type, value }) {
      try {
        if (type === 2) {
          let has = this.dataList.find(v => v.goodsId === value.id)
          if (has) {
            return this.submit(has.id, { planVolume: has.planVolume + 1 })
          }
        }

        let params = {
          goodsSaleAfterId: this.currentGoodsSaleAfterId,
          planVolume: 1,
          // remark: null
        }

        if (type === 1) {
          params.goodsGroupId = value.id
          params.detailMap = value.detailMap
          params.planVolume = value.num
        } else {
          params.goodsId = value.id
        }

        this.loading.submit = true
        await request.post('/goods_sale_after/detail', params)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    itemClick (item) {
      this.formData = item
      this.status.form = true
    },
    async itemDel (item) {
      this.loading.del = true
      try {
        await request.delete('/goods_sale_after/detail/' + item.id)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.del = false
    },
    async submit (id, data) {
      try {
        await request.put('/goods_sale_after/detail/' + id, data)
        this.$Message.success('操作成功')
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
    },
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async handleLoadData (item, callback) {
      try {
        const res = await request.get('/goods_sale_after/detail', {
          pid: item.id
        })
        callback(res)
      } catch (e) {
        callback([])
        this.$Modal.error({
          title: '获取失败',
          content: e.message
        })
      }
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.currentGoodsSaleAfterId) {
        params.goodsSaleAfterId = this.currentGoodsSaleAfterId
      }

      let url = null
      if (this.status.mode) {
        url = '/goods_sale_after/detail'
        params.noChild = 1
      } else {
        url = '/goods_sale_after/detail/count'
        params.noGroup = 1
      }

      this.loading.load = true
      try {
        const res = await request.get(url, Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach((item, id) => {
          if (item.goodsGroupId) {
            item.goodsUseTypeText = null
            item._loading = false
            item.children = []
          } else {
            item.goodsUseTypeText = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType).label
          }
          if (!item.id) {
            item.id = id
          }
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
      this.loadSaleData()
    }
  },
  mounted () {
    this.onRowsChange(999)
  }
}
</script>
